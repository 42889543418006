
import { Outlet } from 'react-router-dom';
import Header from '../components/ui/Header';
import Sidebar from '../components/ui/Sidebar';


export default function MainLayout() {
  return (
    <div>
    <Header/>
    <div className="lg:flex justify-between">
    <div className="lg:block hidden lg:w-[20%] "><Sidebar /></div>
    <div className="lg:w-[70%] mx-auto">
    <Outlet/>
    </div>
    
    </div>
    </div>
  );
}
