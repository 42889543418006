import { ToastContainer } from "react-toastify";
import MainLayout from "./layouts/MainLayout";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div >
      <ToastContainer/>
      <MainLayout/>
    </div>
  );
}

export default App;
