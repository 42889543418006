import React from "react";
import './Pagination.css'
import { Link } from "react-router-dom";



const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <div className="pagination">
      <Link to="" onClick={() => onPageChange(currentPage - 1)} >
        &laquo;
      </Link>
      
      {pages.map((page) => (
        <Link
          key={page}
          to=""
          onClick={() => onPageChange(page)}
          className={currentPage === page ? "active" : ""}
        >
          {page}
        </Link>
      ))}
     
      <Link to="" onClick={() => onPageChange(currentPage + 1)}>
        &raquo;
      </Link>
    </div>
  );
};

export default Pagination;
