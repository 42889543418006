import React from 'react';
import loading from '../Assets/loading.gif'

const Loading = () => {
    return (
        <div className='mx-auto w-11/12 lg:w-10/12'>
            <img src={loading} alt="" srcset="" className='mx-auto'/>
        </div>
    );
};

export default Loading;