
import { useEffect, useState } from "react";
import { TbListDetails } from "react-icons/tb";
import { Link } from "react-router-dom";

export default function OrderCard({ order }) {
    const { deliveryInfo, orderSummary, _id } = order;
    const { deliveryFee, subtotal, total, products } = orderSummary;
    const [formattedCreatedAt, setFormattedCreatedAt] = useState('');

    useEffect(() => {
        const createdAtDate = new Date(order.createdAt);
        const formattedCreatedAt = createdAtDate.toLocaleString('en-US', {
            timeZone: 'Asia/Dhaka', 
            day: 'numeric',
            year: 'numeric',
            month: 'long',
          hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
        setFormattedCreatedAt(formattedCreatedAt);
    }, [order.createdAt]);

    return (
        <tbody className="">
          <tr className="border">
            <td>1</td>
            <td>{deliveryInfo.name} ( {formattedCreatedAt} )</td>
            <td>
            {products.map((product, index) => (
             <tr>
              <td>{product.name}</td>
             </tr>
              ))}
            </td>
           <td>
           {products.map((product, index) => (
             <tr key={index}>
              <td>{product.amount} {product.weight} x {product.quantity} </td>
             </tr>
              ))}
           </td>
           <td><Link to={`/singleorder/${_id}`}><TbListDetails /> </Link></td>
          </tr>
            
        </tbody>
  
    );
  }
  
