import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";


const Sidebar = () => {
    return (
<div className="drawer lg:drawer-open">
  <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
  <div className="drawer-content flex flex-col items-center justify-center">
    <label htmlFor="my-drawer-2" className=" drawer-button lg:hidden"><GiHamburgerMenu /></label>
  </div> 
  <div className="drawer-side ">
    <label htmlFor="my-drawer-2" aria-label="close sidebar" className="drawer-overlay"></label> 
    <ul className="menu p-4 w-72 min-h-full bg-black text-white h-screen fixed">
      <Link to='/addproduct' className="">Add Product</Link>
      <Link to='/getproduct' className="my-5">Get Product</Link>
      <Link to='/getorder'>Get Order</Link>
    </ul>
  
  </div>
</div>
    );
};

export default Sidebar;