import React from 'react';
import Loading from '../components/Loading';
import { useGetOrderQuery } from '../redux/features/order/orderApi';
import OrderCard from '../components/ui/OrderCard';

const Getorder = () => {
  const { data, isLoading, isError } = useGetOrderQuery(undefined);
  if (isLoading) {
    return <div><Loading/></div>;
  }

  if (isError) {
    return <div>Error loading orders</div>;
  }
  return (
<div className="w-full overflow-auto">
      <table className="w-full">
      <thead>
        <tr className="border">
                <th className="text-left">SL</th>
                <th className="text-left">Customer Name</th>
                <th className="text-left">Product Name</th>
                <th className="text-left">Quantity</th>
                <th className="text-left">Details</th>
            </tr>
        </thead>
      {data?.orders?.map((order) => (
        <OrderCard key={order._id} order={order} />
      ))}
      </table>
    </div>
  );
};

export default Getorder;