import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useCreateProductMutation } from '../redux/features/products/productApi';

const Addproduct = () => {
    const [image, setImage] = useState('');
    const [createProduct] = useCreateProductMutation();
    const { register, handleSubmit , reset } = useForm();
  
    const uploadImage = async (e) => {
      e.preventDefault();
      const files = e.target.files;
      const data = new FormData();
      data.append('file', files[0]);
      data.append('upload_preset', 'm2q12jof');
  
      const res = await fetch(
        'https://api.cloudinary.com/v1_1/dwunlkibs/image/upload',
        {
          method: 'POST',
          body: data
        }
      );
      const file = await res.json();
  
      setImage(file.secure_url);
    };
  
    const onSubmit = async (data) => {
      const productData = {
        img: image,
        name: data.productName,
        price: parseFloat(data.productPrice), // Convert to number
        category: data.productCategory,
        weight: data.productWeight,
        amount: data.productAmount,
        discount: parseFloat(data.productDiscount) || 0 // Convert to number, default to 0 if empty
      };
  
      try {
        await createProduct(productData);
        toast.success('Product added successfully')
        reset();
      } catch (error) {
        console.error('Error adding product:', error);
      }
    };
  
    return (
<div className="mx-auto w-11/12 lg:w-10/12">
    <h1 className="mt-5 text-center font-bold text-xl">Add Product</h1>
     <div className="border border-gray-300 rounded-md px-10 py-4 my-8 ">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Product Name */}
        <div className="mb-3 w-full">
          <label>Product Name</label>
          <input type="text" className="w-full b rounded-[5px] p-[10px] bg-[#fafafa] border hover:bg-white focus:ring-1 focus:outline-none focus:ring-gray-400 focus:bg-white mt-1"  placeholder="Product Name" {...register("productName")} required />
        </div>

        {/* Product Price */}
        <div className="mb-3">
          <label>Product Price</label>
          <input type="number" className="w-full b rounded-[5px] p-[10px] bg-[#fafafa] border hover:bg-white focus:ring-1 focus:outline-none focus:ring-gray-400 focus:bg-white mt-1" placeholder="Product Price" {...register("productPrice")} required />
        </div>

        {/* Product Discount */}
        <div className="mb-3">
          <label>Product Discount</label>
          <input type="number" className="w-full b rounded-[5px] p-[10px] bg-[#fafafa] border hover:bg-white focus:ring-1 focus:outline-none focus:ring-gray-400 focus:bg-white mt-1"placeholder="Product Discount" {...register("productDiscount")} />
        </div>
        {/* Product Amount */}
        <div className="mb-3">
          <label>Product Amount</label>
          <input type="text" className="w-full b rounded-[5px] p-[10px] bg-[#fafafa] border hover:bg-white focus:ring-1 focus:outline-none focus:ring-gray-400 focus:bg-white mt-1" placeholder="Product Amount" {...register("productAmount")} required />
        </div>
        {/* Product Weight */}
        <div className="mb-3">
          <label>Product Weight</label>
          <input type="text" className="w-full b rounded-[5px] p-[10px] bg-[#fafafa] border hover:bg-white focus:ring-1 focus:outline-none focus:ring-gray-400 focus:bg-white mt-1"placeholder="Product Weight" {...register("productWeight")} required />
        </div>

        {/* Product Category */}
        <div className="mb-3">
          <label>Product Category</label>
          <input type="text" className="w-full b rounded-[5px] p-[10px] bg-[#fafafa] border hover:bg-white focus:ring-1 focus:outline-none focus:ring-gray-400 focus:bg-white mt-1"placeholder="Product Category" {...register("productCategory")} required />
        </div>

        {/* Product Image */}
        <div className="mb-3">
          <label>Product Image</label>
          <input type="file" name="file" placeholder="Upload an image" onChange={uploadImage}  className="w-full b rounded-[5px] p-[10px] bg-[#fafafa] border hover:bg-white focus:ring-1 focus:outline-none focus:ring-gray-400 focus:bg-white mt-1" required/>
          {image && <img src={image} style={{ width: '300px' }} alt="Product" />}
        </div>

        <input className="bg-[#06bf6c8e] py-2 px-6 text-[16px] font-medium w-full rounded-lg cursor-pointer" type="submit" value="Add Product" />
      </form>
    </div>
   </div>
    );
};

export default Addproduct;