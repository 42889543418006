import { baseApi } from "../../api/baseApi"


const orderApi=baseApi.injectEndpoints({
    endpoints:(builder)=>({
        getOrder: builder.query({
            query: () => `/order`,
          }),
        getSingleOrder: builder.query({
            query: (id) => `/order/${id}`,
          }),

    }),
})

export const {useGetOrderQuery,useGetSingleOrderQuery}=orderApi