import React from 'react';
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';
import logo from '../../Assets/logo.png'


const Header = () => {
    return (
      <nav className="bg-white border sticky top-0 z-50 flex items-center  ">
     <div className='mx-auto w-11/12 lg:w-10/12'> 
     <div className="flex items-center justify-between ">
       <div >
       <img src={logo} alt="" className='lg:block hidden'/>
        <div  className='lg:hidden block'>
        <Sidebar/>
        </div>
        </div>
        <ul className="py-8">
        <Link to='/dashboard'>Login</Link>
        </ul>
      </div>
     </div>
    </nav>
    );
};

export default Header;