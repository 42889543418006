import React from 'react';
import { useParams } from 'react-router-dom';
import { useUpdateProductMutation } from '../redux/features/products/productApi';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const Updateproduct = () => {
    const { id } = useParams();
    const [updateProduct, { isLoading, isError, error }] = useUpdateProductMutation();
    const { register, handleSubmit, reset } = useForm(); 
  
    const onSubmit = async (values) => {
      try {
        const updatedFields = {};
  
        for (const key in values) {
          if (values[key] !== '' && values[key] !== undefined) {
            updatedFields[key] = values[key];
          }
        }
  
        await updateProduct({ id, body: updatedFields });
  
        toast.success('Product updated successfully')
  
        reset();
      } catch (error) {
        console.error('Error updating product:', error);
      }
    };
    return (
        <div className="mx-auto w-11/12 lg:w-10/12">
        <h1 className="mt-5 text-center font-bold text-xl">Update Product</h1>
        <form onSubmit={handleSubmit(onSubmit)} className='border border-gray-300 rounded-md px-10 py-4 my-8'>
         {/* Product Name */}
         <div className="mb-3 w-full">
           <label>Product Name</label>
           <input type="text" className="w-full b rounded-[5px] p-[10px] bg-[#fafafa] border hover:bg-white focus:ring-1 focus:outline-none focus:ring-gray-400 focus:bg-white mt-1"  placeholder="Product Name" {...register("name")}   />
         </div>
 
         {/* Product Price */}
         <div className="mb-3">
           <label>Product Price</label>
           <input type="number" className="w-full b rounded-[5px] p-[10px] bg-[#fafafa] border hover:bg-white focus:ring-1 focus:outline-none focus:ring-gray-400 focus:bg-white mt-1" placeholder="Product Price" {...register("price")}   />
         </div>
 
         {/* Product Discount */}
         <div className="mb-3">
           <label>Product Discount</label>
           <input type="number" className="w-full b rounded-[5px] p-[10px] bg-[#fafafa] border hover:bg-white focus:ring-1 focus:outline-none focus:ring-gray-400 focus:bg-white mt-1"placeholder="Product Discount" {...register("discount")} />
         </div>
         {/* Product Amount */}
         <div className="mb-3">
           <label>Product Amount</label>
           <input type="text" className="w-full b rounded-[5px] p-[10px] bg-[#fafafa] border hover:bg-white focus:ring-1 focus:outline-none focus:ring-gray-400 focus:bg-white mt-1" placeholder="Product Amount" {...register("amount")}   />
         </div>
         {/* Product Weight */}
         <div className="mb-3">
           <label>Product Weight</label>
           <input type="text" className="w-full b rounded-[5px] p-[10px] bg-[#fafafa] border hover:bg-white focus:ring-1 focus:outline-none focus:ring-gray-400 focus:bg-white mt-1"placeholder="Product Weight" {...register("weight")}   />
         </div>
 
         <input className="bg-[#06bf6c8e] py-2 px-6 text-[16px] font-medium w-full rounded-lg cursor-pointer" type="submit" value="Update Product" />
       </form>
     </div>
    );
};

export default Updateproduct;