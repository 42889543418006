import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { Link } from 'react-router-dom';
import { useDeleteProductMutation } from "../../redux/features/products/productApi";


export default function ProductCard({ product }) {

const [deleteProduct]=useDeleteProductMutation()

const handleDelete=async(id)=>{
await deleteProduct(id)
}
  return (
    <div>
      <div className="h-[100%] p-5  text-center">
          <img src={product?.img} alt="product" />
          <h1 className="text-lg font-medium h-14 mt-4 text-[#1a1a1a]">{product?.name}</h1>
        <p className='text-[#06BF6D] text-xs font-medium'>{product?.amount} <span className='text-[#1a1a1a] font-medium'>{product?.weight}</span></p>
        {
          product?.discount?
          <>
          <p className="text-lg font-bold text-[#e43215] my-1">&#2547; {product?.discount} <span className='line-through text-xs pt-2 text-[#323131]'>&#2547; {product?.price}</span></p>
          </>
          :<p className="text-lg font-bold text-[#323131] my-1">&#2547; {product?.price}</p>
        }
       <div className='flex justify-between'>
       <Link  className='' to={`/updateproduct/${product._id}`}><CiEdit /></Link>
        <button className='' onClick={()=>handleDelete(product._id)}><MdDelete /></button>
       </div>
      </div>
    </div>
  );
}
