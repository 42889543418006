import { baseApi } from "../../api/baseApi"

const productApi=baseApi.injectEndpoints({
    endpoints:(builder)=>({

        getProducts: builder.query({
            query: ({ page, limit }) => `/products?page=${page}&limit=${limit}`,
            providesTags:["product appear"]
          }),

        singleProducts:builder.query({
            query:(id)=>`/products/${id}`
       }),
       createProduct: builder.mutation({
        query: (newProduct) => ({
            url: `/products`,
            method: 'POST',
            body: newProduct
        }),
    }),
    updateProduct: builder.mutation({
      query: (data ) => ({
        url: `/products/${data.id}`,
        method: 'PATCH', 
        body: data.body,
      }),
    }),
    deleteProduct: builder.mutation({
      query: (id ) => ({
        url: `/products/${id}`,
        method: 'DELETE', 
        
      }),
      providesTags:["product appear"]
    }),

    }),
})

export const {useGetProductsQuery,useSingleProductsQuery,useCreateProductMutation,useUpdateProductMutation,useDeleteProductMutation}=productApi