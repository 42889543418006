import  { createBrowserRouter} from'react-router-dom'
import App from '../App'
import NotFound from '../pages/NotFound'
import Dashboard from '../pages/Dashboard'
import Addproduct from '../pages/Addproduct'
import Getorder from '../pages/Getorder'
import GetProduct from '../pages/GetProduct'
import Updateproduct from '../pages/Updateproduct'




  export const router = createBrowserRouter([
    {
      path: '/',
      element: <App/>,
      children: [
        {
          index: true,
          element: <Dashboard/>,
        },
        {
          path: '/addproduct',
          element: <Addproduct />,
        },
        {
          path: '/getorder',
          element: <Getorder />,
        },
        {
          path: '/getproduct',
          element: <GetProduct />,
        },
        {
          path: '/updateproduct/:id',
          element: <Updateproduct />,
        },
        
      ],
    },
  
    {
      path: '*',
      element: <NotFound />,
    },
  ])