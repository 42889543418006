import React, { useState } from 'react';
import { useGetProductsQuery } from '../redux/features/products/productApi';
import Pagination from '../components/ui/Pagination';
import ProductCard from '../components/ui/ProductCard';
import Loading from '../components/Loading';

const GetProduct = () => {
    const [page, setPage] = useState(1);
    const limit = 16; 
    const { data, isLoading } = useGetProductsQuery({ page, limit },{refetchOnMountOrArgChange:true,pollingInterval:30000});
    const totalPages = data?.meta?.page || 1; 
  
    const handlePageChange = (page) => {
      setPage(page);
    };
  
    if (isLoading) {
      return <div><Loading/></div>;
    }
    return (
        <div className="mx-auto w-11/12 lg:w-10/12">
        <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-5 items-center justify-center ">
         { 
           data?.data?.map((product) => (
            <>
             <ProductCard key={product._id} product={product} />
            </>
           ))
         }
         
       </div>
       <Pagination totalPages={totalPages} currentPage={page} onPageChange={handlePageChange} />
      </div>
    );
};

export default GetProduct;